import { Text } from "@mantine/core"
import { Spinner } from "components/loading/Spinner.component"
import React from "react"
import { useParams } from "react-router-dom"

import { Deposit } from "./components/Deposit.component"
import { useTokenDistribution } from "./queries/adminQueries"

const SingleDistributionPage = () => {
  const { distributionId } = useParams()
  const { data: distributionInfo, isLoading } = useTokenDistribution(distributionId || null)

  if (isLoading) {
    return <Spinner />
  }
  if (!distributionInfo) {
    return (
      <Text>
        An error occurred fetching the distribution. Double check the id. Are you sure it exists?
      </Text>
    )
  }

  return <Deposit distribution={distributionInfo} />
}
export default SingleDistributionPage
