import { Text } from "@mantine/core"
import { Flex, Box } from "components/Layout.component"
import React from "react"
import styled from "styled-components"

import { connectorLocalStorageKey, walletLocalStorageKey } from "./config"
import { Config, ConnectorNames, Login } from "./types"

interface Props {
  walletConfig: Config
  login: Login
  closeModal: () => void
}

const WalletButton = styled(Flex)`
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 6px 8px;
  align-items: center;
`

export const WalletCard: React.FC<Props> = ({ login, walletConfig, closeModal }) => {
  const { title, icon: Icon } = walletConfig

  return (
    <WalletButton
      onClick={() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

        // Since iOS does not support Trust Wallet we fall back to WalletConnect
        if (walletConfig.title === "Trust Wallet" && isIOS) {
          login(ConnectorNames.WalletConnect)
        } else {
          login(walletConfig.connectorId)
        }

        localStorage.setItem(walletLocalStorageKey, walletConfig.title)
        localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
        closeModal()
      }}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      <Icon width="40px" mb="8px" />
      <Box ml={2}>
        <Text size="sm">{title}</Text>
      </Box>
    </WalletButton>
  )
}
