import { FC } from "react"

import { SvgProps } from "./svg"
export enum ConnectorNames {
  Injected = "injected",
  WalletConnect = "walletconnect",
  BSC = "bsc",
}
export interface Config {
  title: string
  icon: FC<SvgProps>
  connectorId: ConnectorNames
  priority: number
}
export type Login = (connectorId: ConnectorNames) => void
