const breakpoints: any = ["576px", "768px", "992px", "1200px", "1400px"]
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.tablet = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]

const fontSizes: any = [12, 14, 16, 20, 24, 32, 48, 72]
fontSizes.body = fontSizes[2]
fontSizes.small = fontSizes[1]
fontSizes.xs = fontSizes[0]
fontSizes.subtitle = fontSizes[3]

const colors = {
  primary: "#1FCC92",
  secondary: "#0055FF",
  backgroundPrimary: "#3C1642",
  backgroundSecondary: "#4D2154",
  backgroundTertiary: "#311435",
  textPrimary: "#FFFFFF",
  textSecondary: "#000000",
  white: "#FFFFFF",
  cardBackgroundPrimary: "#421948",
  live: "#FF8080",
  gold: "#F59E0B",
  upcoming: "#CCCC52",
} as const

export const theme = {
  //idxes 0, 1, 2,  3,  4,  5,  6,  7,  8,  9,  10, 11
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 96],
  colors: colors,
  fontSizes: fontSizes,
  breakpoints: breakpoints,
  mediaQueries: {
    xs: `(min-width: ${breakpoints[0]})`,
    sm: `(min-width: ${breakpoints[1]})`,
    tablet: `(min-width: ${breakpoints[1]})`,
    md: `(min-width: ${breakpoints[2]})`,
    lg: `(min-width: ${breakpoints[3]})`,
    xl: `(min-width: ${breakpoints[4]})`,
  },
}

export type ColorKeys = keyof typeof colors
