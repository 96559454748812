import { Stepper, Text } from "@mantine/core"
import { Box, Flex } from "components/Layout.component"
import { Spinner } from "components/loading/Spinner.component"
import { useIsBuyerOrCreator } from "features/tokendistributor/queries/adminQueries"
import React from "react"

import { CreateTokenDistribution } from "./components/CreateDistribution.component"
import { Deposit } from "./components/Deposit.component"
import { useUncompletedDistribution } from "./queries/adminQueries"

function CreateStepper() {
  const { data: uncompletedDistribution, isLoading } = useUncompletedDistribution()
  const hasBuyerOrCreatorRoles = useIsBuyerOrCreator()
  const [active, setActive] = React.useState(0)

  React.useEffect(() => {
    if (uncompletedDistribution) {
      if (uncompletedDistribution.abandoned) {
        setActive(0)
      } else {
        setActive(1)
      }
    }
  }, [setActive, uncompletedDistribution])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Box maxWidth="1024px" mx="auto">
        <Stepper active={active} breakpoint="sm">
          <Stepper.Step label="Create" description="Create a token distribution">
            <Box mt={2}>
              {hasBuyerOrCreatorRoles ? (
                <CreateTokenDistribution onComplete={() => setActive(1)} />
              ) : (
                <Text>
                  You do not have the required permissions to create a token distribution. Ask an
                  admin to grant you temporary access.
                </Text>
              )}
            </Box>
          </Stepper.Step>
          <Stepper.Step
            label="Deposit"
            color={
              uncompletedDistribution && uncompletedDistribution.isSettingUp ? "orange" : undefined
            }
            description="Deposit tokens"
          >
            <Box mt={2}>
              {uncompletedDistribution ? (
                <Deposit onAbandon={() => setActive(0)} distribution={uncompletedDistribution} />
              ) : (
                <Spinner />
              )}
            </Box>
          </Stepper.Step>
        </Stepper>
      </Box>
    </>
  )
}

export default CreateStepper
