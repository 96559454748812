import { useChainName } from "constants/chainIds"

import { Title, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { Flex, Box } from "components/Layout.component"
import { ConnectWalletModal } from "features/web3/ConnectWalletModal"
import React from "react"
import { Link } from "react-router-dom"
import { PAGE_URLS } from "routes"
import { theme } from "theme"

const Logo: React.FC = () => {
  // check to see if the screen is on a mobile device
  const isTablet = useMediaQuery(theme.mediaQueries.tablet)
  const isLargeDesktop = useMediaQuery(theme.mediaQueries.lg)
  return (
    <Link to={PAGE_URLS.HOME}>
      <Title order={isLargeDesktop ? 3 : isTablet ? 5 : 3}>SafeLaunch</Title>
    </Link>
  )
}

const LargeHeader: React.FC = () => {
  const chainName = useChainName()
  return (
    <Box>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" justifyContent="flex-start">
          <Logo />
        </Flex>
        <Flex alignItems="center">
          <Flex alignItems="center" mr={2}>
            <Title order={6}>{chainName}</Title>
          </Flex>
          <ConnectWalletModal />
        </Flex>
      </Flex>
    </Box>
  )
}

export const Header: React.FC = () => {
  return <LargeHeader />
}
