import BinanceChain from "./svg/icons/BinanceChain"
import Coin98 from "./svg/icons/Coin98"
import MathWallet from "./svg/icons/MathWallet"
import Metamask from "./svg/icons/Metamask"
import SafePal from "./svg/icons/SafePal"
import TokenPocket from "./svg/icons/TokenPocket"
import TrustWallet from "./svg/icons/TrustWallet"
import WalletConnect from "./svg/icons/WalletConnect"
import { Config, ConnectorNames } from "./types"

export const connectorLocalStorageKey = "connectorId"
export const walletLocalStorageKey = "wallet"

export const connectors: Config[] = [
  {
    title: "Metamask",
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
    priority: 1,
  },
  {
    title: "WalletConnect",
    icon: WalletConnect,
    connectorId: ConnectorNames.WalletConnect,
    priority: 2,
  },
  {
    title: "Trust Wallet",
    icon: TrustWallet,
    connectorId: ConnectorNames.Injected,
    priority: 3,
  },
  {
    title: "MathWallet",
    icon: MathWallet,
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
  {
    title: "TokenPocket",
    icon: TokenPocket,
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },

  {
    title: "Binance Chain",
    icon: BinanceChain,
    connectorId: ConnectorNames.BSC,
    priority: 999,
  },
  {
    title: "SafePal",
    icon: SafePal,
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
  {
    title: "Coin98",
    icon: Coin98,
    connectorId: ConnectorNames.Injected,
    priority: 999,
  },
]
