// Set of helper functions to facilitate wallet setup

import { BSC_MAINNET_ID } from "constants/chainIds"
import { BSCSCAN_URL } from "constants/urls"

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 */
export const setupNetwork = async () => {
  const provider = window.ethereum
  if (provider) {
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${BSC_MAINNET_ID.toString(16)}`,
            chainName: "Binance Smart Chain Mainnet",
            nativeCurrency: {
              name: "BNB",
              symbol: "bnb",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-dataseed1.binance.org"],
            blockExplorerUrls: [`${BSCSCAN_URL}/`],
          },
        ],
      })
      return true
    } catch (error) {
      console.error("Failed to setup the network in Metamask:", error)
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}
