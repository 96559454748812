import { PROVIDERS, ENTITIES, FEATURES } from "constants/queryKeys"

import { useWeb3React } from "@web3-react/core"
import { ethers, BigNumber } from "ethers"
import { useSnackbar } from "notistack"
import { useQuery, useMutation, useQueryClient } from "react-query"
import invariant from "tiny-invariant"

import { useTokenContract, isAddress } from "./useContract.hook"

interface ISpecificAccount {
  contractAddress: string
  account?: string | null
}

interface IAllowance extends ISpecificAccount {
  spender: string
}

export const erc20Keys = {
  all: [{ feature: FEATURES.ERC20, provider: PROVIDERS.WEB3 }] as const,
  detail: {
    all: (contractAddress: string) =>
      [{ ...erc20Keys.all[0], contractAddress, entity: ENTITIES.ITEM }] as const,
    // does not rely on account
    decimals: ({ contractAddress }: { contractAddress: string }) =>
      [{ ...erc20Keys.all[0], contractAddress, variable: "decimals" }] as const,
    // relies on account
    balanceOf: ({ contractAddress, account }: ISpecificAccount) =>
      [{ ...erc20Keys.all[0], contractAddress, account, variable: "balanceOf" }] as const,
    // relies on account and spender
    allowance: ({ contractAddress, account, spender }: IAllowance) =>
      [{ ...erc20Keys.all[0], contractAddress, account, spender, variable: "allowance" }] as const,
  },
}

export const useTokenBalance = (tokenAddress: string) => {
  const { account } = useWeb3React()
  const contract = useTokenContract(tokenAddress)

  return useQuery<BigNumber>(
    erc20Keys.detail.balanceOf({ contractAddress: tokenAddress, account }),
    async () => {
      invariant(account, "Account not given")
      invariant(contract, "Contract not given")
      invariant(isAddress(tokenAddress), "Token address is invalid")

      return contract.balanceOf(account)
    },
    {
      enabled: Boolean(account) && Boolean(contract) && Boolean(isAddress(tokenAddress)),
    }
  )
}

export const useTokenDecimals = (tokenAddress: string) => {
  const contract = useTokenContract(tokenAddress)

  // return type is a number as the ABI specifies uint8 return value
  return useQuery<number>(
    erc20Keys.detail.decimals({ contractAddress: tokenAddress }),
    async () => {
      invariant(contract, "Contract not given")

      return contract.decimals()
    },
    {
      enabled: Boolean(contract) && Boolean(isAddress(tokenAddress)),
      // this value can never change
      staleTime: Infinity,
    }
  )
}

export const useTokenAllowance = (tokenAddress: string, spender: string) => {
  const { account } = useWeb3React()
  const contract = useTokenContract(tokenAddress)

  return useQuery<BigNumber>(
    erc20Keys.detail.allowance({ contractAddress: tokenAddress, account, spender }),
    async () => {
      invariant(account, "Account not given")
      invariant(contract, "Contract not given")
      invariant(isAddress(spender), "Spender address is invalid")
      invariant(isAddress(tokenAddress), "Token address is invalid")

      return contract.allowance(account, spender)
    },
    {
      enabled: Boolean(account) && Boolean(isAddress(tokenAddress)) && Boolean(isAddress(spender)),
    }
  )
}

const DEFAULT_APPROVAL_AMOUNT = ethers.constants.MaxUint256

export const useApproval = (tokenAddress: string, spender: string) => {
  const { account } = useWeb3React()
  const { data: allowance } = useTokenAllowance(tokenAddress, spender)
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const contract = useTokenContract(tokenAddress)

  return useMutation(
    async () => {
      invariant(contract, "Contract not given")
      invariant(isAddress(spender), "Spender address is invalid")
      invariant(isAddress(tokenAddress), "Token address is invalid")

      if (allowance && allowance.gte(DEFAULT_APPROVAL_AMOUNT)) {
        return
      }

      const tx = await contract.approve(spender, DEFAULT_APPROVAL_AMOUNT)
      await tx.wait()

      return DEFAULT_APPROVAL_AMOUNT
    },
    {
      onSuccess: (result) => {
        enqueueSnackbar(`Approval success`, { variant: "success" })
        // update new allowance
        queryClient.setQueriesData(
          erc20Keys.detail.allowance({ contractAddress: tokenAddress, account, spender }),
          () => result
        )
        // invalidate old allowance. We need to return this to await it
        return queryClient.invalidateQueries(
          erc20Keys.detail.allowance({ contractAddress: tokenAddress, account, spender })
        )
      },
    }
  )
}
