import {
  ETH_MAINNET_ID,
  BSC_MAINNET_ID,
  POLYGON_MAINNET_ID,
  AVAX_MAINNET_ID,
  isSupportedChain,
} from "constants/chainIds"

import { useWeb3React } from "@web3-react/core"
import invariant from "tiny-invariant"

// Addresses should NEVER be imported directly. They should always be reliant
// on the chainId.

const USDAddresses = {
  // BUSD
  [BSC_MAINNET_ID]: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  // USDT
  [ETH_MAINNET_ID]: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  // USDT
  [POLYGON_MAINNET_ID]: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  // USDC
  [AVAX_MAINNET_ID]: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
}

const tokenDistributorAddresses = {
  [BSC_MAINNET_ID]: "0x2794A45B10113D2bd7ED28AF677077c75bB308D0",
  [ETH_MAINNET_ID]: "0xc1E1998Cabed811FC532EB2DB67139234aE58e29",
  [POLYGON_MAINNET_ID]: "0x3B163d70C0da61c6E18ff988F8e4251de2A6b22a",
  [AVAX_MAINNET_ID]: "0x3B163d70C0da61c6E18ff988F8e4251de2A6b22a",
}

const shopkeeperAddresses = {
  [BSC_MAINNET_ID]: "0x89895d1793C29C9d19C7861DECDE46087AC54C87",
  [ETH_MAINNET_ID]: "0xa445c6b6BBda17369c93f911f2AcfD00Cfc18e01",
  [POLYGON_MAINNET_ID]: "0xeBc71Cc01Fb873C87F63719fC807f0252cb77291",
  [AVAX_MAINNET_ID]: "0xeBc71Cc01Fb873C87F63719fC807f0252cb77291",
}

// Returns the USD stablecoin address. BUSD on BSC and USDT on ETH.
export const useUSDAddress = () => {
  const { chainId } = useWeb3React()
  invariant(isSupportedChain(chainId), `Chain id ${chainId} not supported`)

  const address = USDAddresses[chainId]
  invariant(address, `USD address on chain id ${chainId} not supported!`)

  return address
}

export const useTokenDistributorAddress = () => {
  const { chainId } = useWeb3React()
  invariant(isSupportedChain(chainId), `Chain id ${chainId} not supported`)

  const address = tokenDistributorAddresses[chainId]
  invariant(address, `Token distributor address on chain id ${chainId} not supported!`)

  return address
}

export const useShopkeeperAddress = () => {
  const { chainId } = useWeb3React()
  invariant(isSupportedChain(chainId), `Chain id ${chainId} not supported`)

  const address = shopkeeperAddresses[chainId]
  invariant(address, `Shopkeeper address on chain id ${chainId} not supported!`)

  return address
}
