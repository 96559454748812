import type { IDistributionInfo } from "./types"

export const selectPendingDistribution =
  (creator?: string | null) => (distributions: IDistributionInfo[]) => {
    if (!creator) {
      return null
    }

    const result = distributions.filter(
      (d: IDistributionInfo) => d.isSettingUp && !d.abandoned && d.creator === creator
    )
    return result.length ? result[0] : null
  }

export const selectDistributionById =
  (distributionId: string | null) => (distributions: IDistributionInfo[]) => {
    if (!distributionId) {
      return null
    }
    const result = distributions.filter((d) => d.distributionId === Number(distributionId))
    return result.length ? result[0] : null
  }

export const selectActiveDistributions = (distributions: IDistributionInfo[]) =>
  distributions.filter((d) => !d.abandoned)

export const wrangleDistribution = (
  contractDistributionInfo: any[],
  i: number
): IDistributionInfo => {
  const [
    name,
    distributionToken,
    totalRaised,
    totalDistributionTokenWithdrawn,
    paused,
    isSettingUp,
    creator,
    abandoned,
  ] = contractDistributionInfo

  return {
    distributionId: i,
    name,
    distributionToken,
    totalRaised,
    totalDistributionTokenWithdrawn,
    paused,
    isSettingUp,
    creator,
    abandoned,
  }
}
