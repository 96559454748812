import format from "date-fns/format"
import formatDistanceToNow from "date-fns/formatDistanceToNow"

// iso string to human readable string
export const isoToHuman = (isoDate: string) => {
  const date = new Date(isoDate)
  return format(date, "PP @  p")
}

// used for formatting block timestamps
export const secondsToHuman = (seconds: number) => {
  const date = new Date(seconds * 1000)
  return format(date, "PP @  p")
}

export const secondsToNow = (seconds: number) => {
  return formatDistanceToNow(new Date(seconds * 1000))
}
