import { useWeb3React } from "@web3-react/core"
import { BigNumber, BigNumberish, Contract } from "ethers"
import { useHasBuyerRole } from "features/shopkeeper/queries"
import { useShopkeeperContract } from "features/shopkeeper/useShopkeeper.hook"
import { useSnackbar } from "notistack"
import { useQueryClient, useMutation } from "react-query"
import invariant from "tiny-invariant"

import type { IContributor } from "../types"

import { useHasCreatorRole } from "./adminQueries"
import { tokenDistributorKeys } from "./queryKeys"
import { IDistributionInfo, IDeposits } from "./types"
import { useTokenDistributorContract } from "./useTokenDistributorContract.hook"

const MAX_CONTRIBUTORS = 200

export const useStartDistribution = (distributionId: BigNumberish) => {
  const contract = useTokenDistributorContract()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { chainId, account } = useWeb3React()

  return useMutation(
    async () => {
      invariant(contract, "Token distributor contract not given")
      const tx = await contract.start(distributionId)

      const receipt = await tx.wait()
      if (receipt.status !== 1) {
        throw new Error("Transaction failed")
      }
    },
    {
      onError: (error: any) => {
        enqueueSnackbar(error?.data?.message || error.message || error, {
          variant: "error",
        })
      },
      onSuccess: () => {
        enqueueSnackbar(`Distribution started`, {
          variant: "success",
        })

        if (account) {
          queryClient.invalidateQueries(tokenDistributorKeys.hasCreatorRole(account, chainId))
        }

        if (chainId) {
          // optimistically update isSettingUp for a nicer UI
          queryClient.setQueryData<IDistributionInfo[]>(
            tokenDistributorKeys.allInfo(chainId),
            (prev) =>
              prev
                ? prev.map((d) => ({
                    ...d,
                    isSettingUp: d.distributionId === distributionId ? false : d.isSettingUp,
                  }))
                : []
          )
        }
      },
    }
  )
}

export const useUndoDeposit = (distributionId: BigNumberish) => {
  const contract = useTokenDistributorContract()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (depositIndex: BigNumberish) => {
      invariant(contract, "Token distributor contract not given")
      const tx = await contract.undoDeposit(distributionId, depositIndex)

      const receipt = await tx.wait()
      if (receipt.status !== 1) {
        throw new Error("Transaction failed")
      }
    },
    {
      onError: (error: any) => {
        enqueueSnackbar(error?.data?.message || error.message || error, {
          variant: "error",
        })
      },
      onSuccess: (_, depositIndex) => {
        enqueueSnackbar(`Deposited tokens removed from deposit index: ${depositIndex}`, {
          variant: "success",
        })

        return queryClient.invalidateQueries(
          tokenDistributorKeys.detail.deposits(distributionId.toString())
        )
      },
    }
  )
}

export const useAbandonDistribution = (distributionId: BigNumberish) => {
  const contract = useTokenDistributorContract()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { chainId } = useWeb3React()

  return useMutation(
    async () => {
      invariant(contract, "Token distributor contract not given")
      const tx = await contract.abandon(distributionId)

      const receipt = await tx.wait()
      if (receipt.status !== 1) {
        throw new Error("Transaction failed")
      }
    },
    {
      onError: (error: any) => {
        enqueueSnackbar(error?.data?.message || error.message || error, {
          variant: "error",
        })
      },
      onSuccess: () => {
        enqueueSnackbar(`Distribution abandoned and credit refunded`, {
          variant: "success",
        })

        if (chainId) {
          // optimistically update isSettingUp for a nicer UI
          queryClient.setQueryData<IDistributionInfo[]>(
            tokenDistributorKeys.allInfo(chainId),
            (prev) =>
              prev
                ? prev.map((d) => ({
                    ...d,
                    abandoned: d.distributionId === distributionId ? true : d.abandoned,
                  }))
                : []
          )
        }
      },
    }
  )
}

interface ICreateMutationParams {
  distributionName: string
  contributors: IContributor[]
  distributionTokenAddress: string
}

export const useCreateDistribution = () => {
  const { enqueueSnackbar } = useSnackbar()
  const contract = useTokenDistributorContract()
  const shopkeeperContract = useShopkeeperContract()
  const { data: hasBuyerRole } = useHasBuyerRole()
  const { data: hasCreatorRole } = useHasCreatorRole()
  const queryClient = useQueryClient()
  const { chainId, account } = useWeb3React()

  return useMutation(
    async ({ distributionName, distributionTokenAddress, contributors }: ICreateMutationParams) => {
      invariant(contract, "Token distributor contract not given")
      invariant(
        contributors.length <= MAX_CONTRIBUTORS,
        `can only create/add ${MAX_CONTRIBUTORS} contributors in each tx. Received: ${contributors.length}`
      )

      // when user is a buyer (paying credits)
      if (hasBuyerRole && !hasCreatorRole) {
        invariant(shopkeeperContract, "Shopkeeper contract not given")
        const createTx = await shopkeeperContract.grantAndCreate(
          distributionName,
          distributionTokenAddress,
          contributors.map(({ address }) => address),
          contributors.map(({ value }) => value)
        )
        const receipt = await createTx.wait()
        if (receipt.status !== 1) {
          throw new Error("Transaction failed")
        }
        const distributionId = receipt.events[2].args[1]
        return distributionId
      }

      // when user is a creator (doesnt use credits)
      const createTx = await contract.create(
        distributionName,
        distributionTokenAddress,
        contributors.map(({ address }) => address),
        contributors.map(({ value }) => value)
      )

      const receipt = await createTx.wait()
      if (receipt.status !== 1) {
        throw new Error("Transaction failed")
      }

      // Note that these are different events to the buyer path
      const distributionId = receipt.events[0].args[1]
      return distributionId
    },
    {
      onError: (error: any) => {
        enqueueSnackbar(error?.data?.message || error.message || error, {
          variant: "error",
        })
      },
      onSuccess: (distributionId: BigNumber, variables) => {
        enqueueSnackbar(`Distribution created with id: ${distributionId}`, {
          variant: "success",
        })
        invariant(account, "account not given")
        // optimistically update this as we know it exists now
        queryClient.setQueryData<IDistributionInfo[]>(
          tokenDistributorKeys.allInfo(chainId),
          (prev) =>
            prev
              ? [
                  ...prev,
                  {
                    distributionId,
                    name: variables.distributionName,
                    distributionToken: variables.distributionTokenAddress,
                    totalRaised: BigNumber.from(0),
                    totalDistributionTokenWithdrawn: BigNumber.from(0),
                    paused: false,
                    isSettingUp: true,
                    creator: account,
                    abandoned: false,
                  },
                ]
              : []
        )
      },
    }
  )
}
interface IAddMutationParams {
  distributionId: BigNumberish
  contributors: IContributor[]
}

interface IAddContributorsParams extends IAddMutationParams {
  contract: Contract
}

async function addContributors({ contract, distributionId, contributors }: IAddContributorsParams) {
  if (contributors.length > MAX_CONTRIBUTORS) {
    throw new Error(
      `can only create/add ${MAX_CONTRIBUTORS} contributors in each tx. Received: ${contributors.length}`
    )
  }

  const addTx = await contract.addContributors(
    distributionId,
    contributors.map(({ address }) => address),
    contributors.map(({ value }) => value)
  )

  const receipt = await addTx.wait()
  if (receipt.status !== 1) {
    throw new Error("Transaction failed")
  }

  return contributors
}

export const useAddContributors = () => {
  const { enqueueSnackbar } = useSnackbar()
  const contract = useTokenDistributorContract()

  return useMutation(
    ({ distributionId, contributors }: IAddMutationParams) => {
      invariant(contract, "Contract not given")

      return addContributors({
        contract,
        distributionId,
        contributors,
      })
    },
    {
      onError: (error: any) => {
        enqueueSnackbar(error?.data?.message || error.message || error, {
          variant: "error",
        })
      },
      onSuccess: (contributors: IContributor[]) => {
        enqueueSnackbar(`Added ${contributors.length} contributors`, {
          variant: "success",
        })
      },
    }
  )
}

interface IDepositParams {
  distributionId: BigNumberish
  unlockTime: BigNumberish
  amountHuman: string
  contract: Contract
  decimals: number
}

async function deposit({
  contract,
  distributionId,
  amountHuman,
  unlockTime,
  decimals,
}: IDepositParams) {
  const depositTx = await contract.deposit(
    distributionId,
    amountHuman.toBigNumber(decimals),
    unlockTime
  )
  const receipt = await depositTx.wait()
  if (receipt.status !== 1) {
    throw new Error("Transaction failed")
  }
  return receipt
}

export const useMakeDeposit = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const contract = useTokenDistributorContract()
  const { chainId } = useWeb3React()

  return useMutation(
    ({ distributionId, amountHuman, unlockTime, decimals }: Omit<IDepositParams, "contract">) => {
      invariant(contract, "Contract not given")

      return deposit({
        contract,
        distributionId,
        amountHuman,
        unlockTime,
        decimals,
      })
    },
    {
      onError: (error: any) => {
        enqueueSnackbar(error?.data?.message || error.message || error, {
          variant: "error",
        })
      },
      onSuccess: (result, { distributionId, amountHuman, unlockTime, decimals }) => {
        enqueueSnackbar(`Deposit created`, {
          variant: "success",
        })
        // optimistically update deposits
        queryClient.setQueryData<IDeposits>(
          tokenDistributorKeys.detail.deposits(distributionId.toString(), chainId),
          (prev) =>
            prev === undefined
              ? {
                  deposits: [
                    {
                      depositId: 0,
                      amount: amountHuman.toBigNumber(decimals),
                      unlockTime: BigNumber.from(unlockTime),
                    },
                  ],
                  totalDeposited: amountHuman.toBigNumber(decimals),
                }
              : {
                  ...prev,
                  deposits: [
                    ...prev.deposits,
                    {
                      depositId: prev.deposits.length,
                      amount: amountHuman.toBigNumber(decimals),
                      unlockTime: BigNumber.from(unlockTime),
                    },
                  ].sort((a, b) => (a.unlockTime.gt(b.unlockTime) ? 1 : -1)),
                }
        )
        return queryClient.invalidateQueries(
          tokenDistributorKeys.detail.deposits(distributionId.toString())
        )
      },
    }
  )
}
