import { useShopkeeperAddress } from "constants/addresses"

import { useContract } from "helpers/useContract.hook"

const abi = [
  "function availableCredit() external view returns (uint256)",
  "function buy(uint256 _amount, address _wallet) external",
  "function grantCreationRoleTo(address _wallet) external",
  "function hasUsedPerms(address _wallet) external view returns (bool)",
  "function refundUnused(address _wallet) external",
  "function BUYER_ROLE() view returns (bytes32)",
  "function hasRole(bytes32 role, address account) external view returns (bool)",
  "function grantAndCreate(string calldata name, address distributionToken, address[] calldata contributors, uint256[] calldata amounts) external",
  "event LogCreateDistribution (address indexed creator, uint256 indexed distributionId, string indexed name, address distributionToken)",
  "event Bought(address indexed buyer, uint256 amount)",
  "event SpentCredit(address indexed grantedTo)",
  "event Refund(address indexed wallet)",
  "event Withdraw(address indexed tokenAddress, uint256 amount)",
  "event PriceChanged(uint256 price)",
  "event CreditChanged(uint256 newCredit)",
]

export const useShopkeeperContractAddress = () => {
  const contractAddress = useShopkeeperAddress()

  return contractAddress
}

export const useShopkeeperContract = () => {
  const contractAddress = useShopkeeperContractAddress()
  return useContract(contractAddress, abi)
}
