import React from "react"
import styled from "styled-components"
import {
  order,
  OrderProps,
  background,
  BackgroundProps,
  position,
  PositionProps,
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  space,
  layout,
  shadow,
  ShadowProps,
  SpaceProps,
  LayoutProps,
  color,
  ColorProps,
  zIndex,
  ZIndexProps,
  typography,
  TypographyProps,
  grid,
  GridProps,
} from "styled-system"

export interface IBoxProps
  extends BorderProps,
    FlexboxProps,
    SpaceProps,
    LayoutProps,
    ZIndexProps,
    PositionProps,
    OrderProps,
    ShadowProps,
    BackgroundProps,
    ColorProps,
    TypographyProps {
  children?: React.ReactNode
}

export const Box = styled.div<IBoxProps>`
  ${space};
  ${layout};
  ${flexbox};
  ${border};
  ${position};
  ${color};
  ${shadow};
  ${zIndex};
  ${order};
  ${background};
  ${typography};
`

export const Flex = styled(Box)<IBoxProps>`
  display: flex;
`

export const Grid = styled(Box)<IBoxProps & GridProps>`
  ${grid};
  display: grid;
`
