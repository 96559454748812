import { isSupportedChain } from "constants/chainIds"

import { Text } from "@mantine/core"
import { useWeb3React } from "@web3-react/core"
import React from "react"

export const ChainIdGuard: React.FC = ({ children }) => {
  const { chainId } = useWeb3React()
  if (!isSupportedChain(chainId)) {
    return <Text>Please connect on BSC or ETH to continue</Text>
  }

  return <>{children}</>
}
