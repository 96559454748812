import { useWeb3React } from "@web3-react/core"
import { BigNumber, BigNumberish } from "ethers"
import { useHasBuyerRole } from "features/shopkeeper/queries"
import { useQuery, UseQueryResult } from "react-query"
import invariant from "tiny-invariant"

import { tokenDistributorKeys } from "./queryKeys"
import {
  selectPendingDistribution,
  wrangleDistribution,
  selectDistributionById,
  selectActiveDistributions,
} from "./selectors"
import type { IDistributionInfo, IDeposits, IDeposit } from "./types"
import { useTokenDistributorContract } from "./useTokenDistributorContract.hook"

// used to check if the user has the creator or temp role which is used to create new distributions
export const useHasCreatorRole = () => {
  const { account, chainId } = useWeb3React()
  const contract = useTokenDistributorContract()

  return useQuery<boolean>(
    tokenDistributorKeys.hasCreatorRole(account, chainId),
    async () => {
      invariant(account, "Account not given")
      invariant(contract, "Contract not given")

      const creatorRole = await contract.CREATOR_ROLE()
      const userHasCreatorRole = await contract.hasRole(creatorRole, account)
      if (userHasCreatorRole) {
        return true
      }
      const tempCreatorRole = await contract.TEMPORARY_CREATOR_ROLE()
      return contract.hasRole(tempCreatorRole, account)
    },
    { enabled: Boolean(account) && Boolean(contract), staleTime: 30_000 }
  )
}

export const useIsBuyerOrCreator = () => {
  const { data: hasBuyerRole } = useHasBuyerRole()
  const { data: hasCreatorRole } = useHasCreatorRole()

  if (hasBuyerRole === undefined || hasCreatorRole === undefined) {
    return null
  }

  return hasBuyerRole || hasCreatorRole
}

// gets all deposits for a distribution id
export const useDeposits = (distributionId: BigNumberish) => {
  const { chainId } = useWeb3React()
  const contract = useTokenDistributorContract()

  return useQuery<IDeposits>(
    tokenDistributorKeys.detail.deposits(distributionId.toString(), chainId),
    async () => {
      invariant(contract, "Contract not given")

      const deposits = await contract.getDeposits(distributionId)
      // whilst the contract returns a named tuple, sometimes accessing them by keys
      // doesn't work. So here we access them by indexes
      const totalDeposited = deposits.reduce(
        (acc: BigNumber, [amount, _]: any) => acc.add(amount),
        BigNumber.from(0)
      )

      return {
        deposits: deposits
          .map((d: any, i: number) => ({
            depositId: i,
            amount: d[0],
            unlockTime: d[1],
          }))
          // sort earliest to latest
          .sort((a: IDeposit, b: IDeposit) => (a.unlockTime.gt(b.unlockTime) ? 1 : -1)),
        totalDeposited,
      }
    },
    // query options
    {
      enabled: Boolean(contract),
    }
  )
}

interface IUseAllDistributionInfo {
  <T = IDistributionInfo[]>(select?: (data: IDistributionInfo[]) => T): UseQueryResult<T>
}

export const useAllDistributionInfo: IUseAllDistributionInfo = (select) => {
  const { chainId } = useWeb3React()
  const contract = useTokenDistributorContract()

  return useQuery(
    // query key
    tokenDistributorKeys.allInfo(chainId),
    // return function
    async () => {
      invariant(chainId !== undefined, "ChainId must be supplied")
      invariant(contract, "contract not given")

      const result = await contract.getDistributions()
      // we can use i here as the ids are consistent with indexes
      return result.map((item: any[], i: number) => wrangleDistribution(item, i))
    },
    // query options
    {
      enabled: Boolean(contract) && chainId !== undefined,
      select,
    }
  )
}

// returns the pending distribution for a user if they have one and null
// otherwise
export const useUncompletedDistribution = () => {
  const { account } = useWeb3React()
  return useAllDistributionInfo<IDistributionInfo | null>(selectPendingDistribution(account))
}

// gets information for a specific distribution
export const useTokenDistribution = (distributionId: string | null) => {
  return useAllDistributionInfo<IDistributionInfo | null>(selectDistributionById(distributionId))
}

// gets all distributions that havent been abandoned
export const useActiveTokenDistributions = () => {
  return useAllDistributionInfo<IDistributionInfo[]>(selectActiveDistributions)
}

export const useContributorAddresses = (distributionId: BigNumberish) => {
  const { chainId } = useWeb3React()
  const contract = useTokenDistributorContract()

  return useQuery<string[]>(
    // query key
    tokenDistributorKeys.detail.contributors(distributionId.toString(), chainId),
    // return function
    async () => {
      invariant(chainId !== undefined, "ChainId must be supplied")
      invariant(contract, "contract not given")

      return contract.getContributors(distributionId)
    },
    // query options
    {
      enabled: Boolean(contract) && chainId !== undefined,
      // this never changes
      staleTime: Infinity,
    }
  )
}
