import { MantineProvider } from "@mantine/core"
import { Web3ReactProvider } from "@web3-react/core"
import { getLibrary } from "features/web3/utils"
import { SnackbarProvider, useSnackbar } from "notistack"
import React from "react"
import { isMobile } from "react-device-detect"
import { QueryClient, QueryClientProvider, QueryCache } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { ThemeProvider as StyledComponentsThemeProvider } from "styled-components"
import { theme as reskinStyled } from "theme"

interface AllTheProviders {
  children: React.ReactNode
}

const HEADER_NOT_FOUND_ERROR = "header not found"

const RQProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error: any) => {
            const errorMessage = error.data?.message || error.message || error
            // These errors occur frequently, so we don't want to show them to
            // the user
            if (errorMessage === HEADER_NOT_FOUND_ERROR) {
              console.error("Multiple header not found messages suppressed")
              return
            }

            enqueueSnackbar(errorMessage, {
              variant: "error",
            })
          },
        }),
        defaultOptions: {
          queries: { notifyOnChangeProps: "tracked", retry: 3 },
        },
      }),
    [enqueueSnackbar]
  )

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export const AllTheProviders: React.FC<AllTheProviders> = ({ children }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      dense={isMobile}
      autoHideDuration={5000}
      maxSnack={3}
    >
      <RQProvider>
        <Web3ReactProvider getLibrary={getLibrary}>
          <MantineProvider>
            <StyledComponentsThemeProvider theme={reskinStyled}>
              <ReactQueryDevtools initialIsOpen={false} />
              {children}
            </StyledComponentsThemeProvider>
          </MantineProvider>
        </Web3ReactProvider>
      </RQProvider>
    </SnackbarProvider>
  )
}
