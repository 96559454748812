import { ENTITIES, PROVIDERS, FEATURES } from "constants/queryKeys"

import { useWeb3React } from "@web3-react/core"
import { BigNumber } from "ethers"
import { useQuery } from "react-query"
import invariant from "tiny-invariant"

import { useShopkeeperContract } from "./useShopkeeper.hook"

export const shopkeeperKeys = {
  all: (chainId?: number) =>
    [{ feature: FEATURES.SHOPKEEPER, provider: PROVIDERS.WEB3, chainId }] as const,
  detail: {
    all: (chainId?: number) =>
      [{ ...shopkeeperKeys.all(chainId)[0], entity: ENTITIES.ITEM }] as const,
    availableCredit: (chainId?: number) =>
      [
        { ...shopkeeperKeys.all(chainId)[0], entity: ENTITIES.ITEM, value: "availableCredit" },
      ] as const,
    isBuyer: (chainId?: number, account?: string | null) =>
      [
        { ...shopkeeperKeys.all(chainId)[0], entity: ENTITIES.ITEM, value: "isBuyer", account },
      ] as const,
  },
}

export const useAvailableCredit = () => {
  const { chainId } = useWeb3React()
  const { data: hasBuyerRole } = useHasBuyerRole()
  const contract = useShopkeeperContract()

  return useQuery<BigNumber>(
    shopkeeperKeys.detail.availableCredit(),
    async function () {
      invariant(chainId, "chainId not given")
      invariant(contract, "contract not given")

      return contract.availableCredit()
    },
    { enabled: Boolean(chainId) && Boolean(hasBuyerRole) && Boolean(contract) }
  )
}

// used to check if the user can interact with the shopkeeper contract
export const useHasBuyerRole = () => {
  const { account, chainId } = useWeb3React()
  const contract = useShopkeeperContract()

  return useQuery<boolean>(
    shopkeeperKeys.detail.isBuyer(chainId, account),
    async () => {
      invariant(account, "Account not given")
      invariant(contract, "contract not given")

      const buyerRole = await contract.BUYER_ROLE()
      return contract.hasRole(buyerRole, account)
    },
    { enabled: Boolean(account) && Boolean(contract), staleTime: Infinity }
  )
}
