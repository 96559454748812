import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export const useScrollOnRouteChange = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    }, 50)
  }, [location.pathname])
}
