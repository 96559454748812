import { useWeb3React } from "@web3-react/core"

export const BSC_MAINNET_ID = 56
export const ETH_MAINNET_ID = 1
export const POLYGON_MAINNET_ID = 137
export const AVAX_MAINNET_ID = 43114

export const DEFAULT_NETWORK_ID = BSC_MAINNET_ID

type SupportedChain =
  | typeof BSC_MAINNET_ID
  | typeof POLYGON_MAINNET_ID
  | typeof ETH_MAINNET_ID
  | typeof AVAX_MAINNET_ID

export const SUPPORTED_CHAINS = [
  BSC_MAINNET_ID,
  POLYGON_MAINNET_ID,
  ETH_MAINNET_ID,
  AVAX_MAINNET_ID,
]

export const isSupportedChain = (chainId?: number): chainId is SupportedChain => {
  if (chainId === undefined) {
    return false
  }
  return SUPPORTED_CHAINS.includes(chainId)
}

const chainIdMap = {
  [BSC_MAINNET_ID]: "BSC Mainnet",
  [ETH_MAINNET_ID]: "Ethereum Mainnet",
  [POLYGON_MAINNET_ID]: "Polygon Mainnet",
  [AVAX_MAINNET_ID]: "Avalanche Mainnet C-Chain",
}

export const useChainName = () => {
  const { chainId } = useWeb3React()
  if (!isSupportedChain(chainId)) {
    return null
  }

  return chainIdMap[chainId]
}
