import { ENTITIES, PROVIDERS } from "constants/queryKeys"

export const tokenDistributorKeys = {
  all: (chainId?: number) =>
    [{ feature: "tokenDistributor", provider: PROVIDERS.WEB3, chainId }] as const,
  list: (account?: string | null, chainId?: number) =>
    [{ ...tokenDistributorKeys.all(chainId)[0], entity: ENTITIES.LIST, account }] as const,
  allInfo: (chainId?: number) =>
    [
      { ...tokenDistributorKeys.all(chainId)[0], entity: ENTITIES.LIST, variable: "allInfo" },
    ] as const,
  hasCreatorRole: (account?: string | null, chainId?: number) =>
    [
      {
        ...tokenDistributorKeys.all(chainId)[0],
        account,
        variable: "hasCreatorRole",
      },
    ] as const,
  useUncompletedDistribution: (account?: string | null, chainId?: number) =>
    [
      {
        ...tokenDistributorKeys.all(chainId)[0],
        account,
        variable: "useUncompletedDistribution",
      },
    ] as const,
  walletDistributionIds: (account?: string | null, chainId?: number) =>
    [
      {
        ...tokenDistributorKeys.all(chainId)[0],
        account,
        variable: "walletDistributionIds",
      },
    ] as const,
  detail: {
    all: (distributionId?: string, chainId?: number) =>
      [
        {
          ...tokenDistributorKeys.all(chainId)[0],
          entity: ENTITIES.ITEM,
          distributionId,
          chainId,
        },
      ] as const,
    deposits: (distributionId?: string, chainId?: number) =>
      [
        {
          ...tokenDistributorKeys.detail.all(distributionId, chainId)[0],
          variable: "deposits",
        },
      ] as const,
    contributors: (distributionId?: string, chainId?: number) =>
      [
        {
          ...tokenDistributorKeys.detail.all(distributionId, chainId)[0],
          variable: "contributors",
        },
      ] as const,
  },
}
