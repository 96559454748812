import { ENTITIES, PROVIDERS, FEATURES } from "constants/queryKeys"
import { BACKEND_BASE_URL } from "constants/urls"

import {
  Button,
  Slider,
  Card,
  TextInput,
  Text,
  Title,
  Group,
  ActionIcon,
  Table,
  Modal,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useClipboard } from "@mantine/hooks"
import { useWeb3React } from "@web3-react/core"
import axios from "axios"
import { Box, Flex } from "components/Layout.component"
import { Spinner } from "components/loading/Spinner.component"
import { ethers } from "ethers"
import { isoToHuman } from "helpers/dates"
import { useSnackbar } from "notistack"
import React from "react"
import { useQuery, useMutation, useQueryClient } from "react-query"
import { Copy } from "tabler-icons-react"
import invariant from "tiny-invariant"
import type { ISignedMessage } from "types"
import { z } from "zod"

const DASHBOARD_URL = `${BACKEND_BASE_URL}/referral/partner/dashboard`

export const referralKeys = {
  all: () => [{ feature: FEATURES.REFERRAL, provider: PROVIDERS.BACKEND }] as const,
  detail: {
    dashboard: () =>
      [{ ...referralKeys.all()[0], entity: ENTITIES.LIST, value: "dashboard" }] as const,
  },
}

interface IBackendComission {
  pending: number
  paid: number
}

interface IBackendPartnerLink {
  id: string
  url: string
  partnerPercentCommissionShare: number
  partnerWalletAddress: string
  createdAt: string
  notes?: string
}

interface IBackendDashboard {
  commission: IBackendComission
  partnerLinks: IBackendPartnerLink[]
  userCommissionRate: number
}

interface IBackendPartnerLinkCreate {
  slug?: string
  partnerWalletAddress: string
  partnerPercentCommissionShare: number
  notes?: string
}

const getDashboard = async (wallet: string) => {
  try {
    const response = await axios.get<IBackendDashboard>(`${DASHBOARD_URL}?wallet=${wallet}`)
    return response.data
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.detail == "User not found") {
        return undefined
      }
      throw new Error(error.response.data.detail)
    } else if (error.request) {
      throw new Error("Request sent but server did not respond")
    } else {
      throw new Error("Unknown error getting dashboard")
    }
  }
}

const createNewLink = async (signedMessage: ISignedMessage) => {
  try {
    const response = await axios.post<IBackendPartnerLink>(`${DASHBOARD_URL}/link`, signedMessage)
    return response.data
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.detail)
    } else if (error.request) {
      throw new Error("Request sent but server did not respond")
    } else {
      throw new Error("Unknown error creating new link ")
    }
  }
}

const useCreateNewLink = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { account, library: provider } = useWeb3React()

  return useMutation(
    async (values: IBackendPartnerLinkCreate) => {
      invariant(account, "wallet not connected")

      const stringifiedMessage = JSON.stringify(values)
      const signer = provider.getSigner()
      const flatSig = await signer.signMessage(stringifiedMessage)
      const sig = ethers.utils.splitSignature(flatSig)
      const signedMessage = {
        unverifiedErc20Address: account,
        unverifiedMessage: stringifiedMessage,
        v: sig.v,
        r: sig.r,
        s: sig.s,
      }

      return createNewLink(signedMessage)
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Link created", { variant: "success" })
        return queryClient.invalidateQueries(referralKeys.all())
      },
      onError: (error: any) => {
        enqueueSnackbar(error.message, { variant: "error" })
      },
    }
  )
}

const useDashboard = () => {
  const { account } = useWeb3React()

  return useQuery<IBackendDashboard | undefined>(
    referralKeys.detail.dashboard(),
    async () => {
      invariant(account, "wallet is required")
      return getDashboard(account)
    },
    {
      enabled: Boolean(account),
    }
  )
}

const CreateNewLinkSchema = z.object({
  partnerWalletAddress: z.string().length(42, "Must be a valid ETH/BSC address"),
  partnerPercentCommissionShare: z.number().min(1).max(100),
  slug: z.string().min(4).max(20),
  notes: z.string().max(1200).optional(),
})

const CreateNewLink = () => {
  const [opened, setOpened] = React.useState(false)
  const { mutate, isLoading } = useCreateNewLink()
  const form = useForm<IBackendPartnerLinkCreate>({
    initialValues: {
      partnerWalletAddress: "",
      partnerPercentCommissionShare: 50,
      slug: "",
      notes: "",
    },
    schema: zodResolver(CreateNewLinkSchema),
  })

  return (
    <>
      <Button size="md" onClick={() => setOpened(true)}>
        Generate Custom Link
      </Button>
      <Modal
        padding="xl"
        opened={opened}
        onClose={() => setOpened(false)}
        title="Generate a referal partner link"
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            mutate(values, {
              onSuccess: () => {
                form.reset()
                setOpened(false)
              },
            })
          })}
        >
          <Box mt={2}>
            <TextInput required label="Custom slug" {...form.getInputProps("slug")} />
            <Text size="xs">This can only be set once and cannot be changed later.</Text>
          </Box>
          <Box mt={2}>
            <TextInput
              required
              label="Partner wallet address"
              {...form.getInputProps("partnerWalletAddress")}
            />
            <Text size="xs">This can only be set once and cannot be changed later.</Text>
          </Box>
          <Box mt={2}>
            <Text size="sm" color="gray">
              Set commission split
            </Text>
            <Slider
              marks={[
                { value: 25, label: "25%" },
                { value: 50, label: "50%" },
                { value: 75, label: "75%%" },
              ]}
              {...form.getInputProps("partnerPercentCommissionShare")}
            />
            <Box mt={3}>
              <Text size="xs">This can only be set once and cannot be changed later.</Text>
            </Box>
          </Box>
          <Box mt={2}>
            <TextInput label="Notes" {...form.getInputProps("notes")} />
            <Text size="xs">This is used for your reference and can be changed later</Text>
          </Box>

          <Group position="right" mt="md">
            <Button type="submit" disabled={isLoading}>
              Submit
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  )
}

const Dashboard = () => {
  const { data: dashboard, isLoading } = useDashboard()
  const clipboard = useClipboard()
  const { enqueueSnackbar } = useSnackbar()

  if (isLoading) {
    return <Spinner />
  }
  if (!dashboard) {
    return <Text>You are not a referral manager</Text>
  }

  return (
    <Box>
      <Title order={1}>Referral Dashboard</Title>
      <Box mt={3}>
        <Card shadow="sm" padding="xl">
          <Title order={2}>Your commission rate: {dashboard.userCommissionRate}%</Title>
          <Box mt={2}>
            <Group>
              <Box>
                <Text>Commission earned</Text>
                <Text>${dashboard.commission.pending}</Text>
              </Box>
              <Box>
                <Text>Commission paid out</Text>
                <Text>${dashboard.commission.paid}</Text>
              </Box>
            </Group>
          </Box>
        </Card>
      </Box>

      <Box mt={3}>
        <Card shadow="sm" padding="xl">
          <Title order={2}>Your custom referral links</Title>
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <Box maxWidth="786px" mt={2}>
                <Text>
                  Create links to share with referral partners. Choose a proportion of your
                  commission to share with each partner. You can only create one link for each
                  partner.
                </Text>
              </Box>
            </Box>
            <Box>
              <CreateNewLink />
            </Box>
          </Flex>

          <Box mt={4}>
            <Table>
              <thead>
                <tr>
                  <th>Referral link</th>
                  <th>Partner commission share (%)</th>
                  <th>Notes</th>
                  <th>Created at</th>
                </tr>
              </thead>
              <tbody>
                {dashboard.partnerLinks.map((partnerLink) => (
                  <tr key={partnerLink.url}>
                    <td>
                      <Box>
                        <Flex>
                          <Text weight={500} color="blue">
                            {partnerLink.url}
                          </Text>
                          <ActionIcon
                            onClick={() => {
                              clipboard.copy(partnerLink.url)
                              enqueueSnackbar("Copied to clipboard", { variant: "success" })
                            }}
                          >
                            <Copy size={16} />
                          </ActionIcon>
                        </Flex>
                        <Box>
                          <Box mt={1}>
                            <Text size="xs">Partner address:</Text>
                          </Box>
                          <Text size="sm">{partnerLink.partnerWalletAddress}</Text>
                        </Box>
                      </Box>
                    </td>
                    <td>
                      {partnerLink.partnerPercentCommissionShare} (
                      {(
                        (partnerLink.partnerPercentCommissionShare * dashboard.userCommissionRate) /
                        100
                      ).toFixed(1)}
                      )
                    </td>
                    <td>{partnerLink.notes}</td>
                    <td>{isoToHuman(partnerLink.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

export default Dashboard
