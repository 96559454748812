import { Select, Title } from "@mantine/core"
import { Box, Flex } from "components/Layout.component"
import { Spinner } from "components/loading/Spinner.component"
import React from "react"

import { Deposit } from "./components/Deposit.component"
import { useTokenDistribution, useActiveTokenDistributions } from "./queries/adminQueries"

const DistributePage = () => {
  const [selectedDistribution, setSelectedDistribution] = React.useState<string | null>(null)
  const { data: distributionInfo } = useTokenDistribution(selectedDistribution)
  const { data: distributions } = useActiveTokenDistributions()

  if (!distributions) {
    return <Spinner />
  }

  return (
    <Box>
      <Title order={1}>Distribute Tokens</Title>

      <Flex mt={2}>
        <Box width="50%">
          <Select
            label="Select Distribution"
            placeholder="Test distribution"
            data={distributions.map((item) => ({
              value: item.distributionId.toString(),
              label: item.name,
            }))}
            value={selectedDistribution}
            onChange={setSelectedDistribution}
          />
        </Box>
      </Flex>
      <Box mt={4}>{distributionInfo && <Deposit distribution={distributionInfo} />}</Box>
    </Box>
  )
}

export default DistributePage
