import { parseUnits } from "@ethersproject/units"
import { BigNumber } from "ethers"
import Numeral from "numeral"

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, tokenDecimals?: number): BigNumber | undefined {
  if (!value || !tokenDecimals) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, tokenDecimals)
    if (typedValueParsed.toString() !== "0") {
      return typedValueParsed
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.error(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

const formatK = (value: string) => {
  return Numeral(value).format("0.[00]a")
}

export const formatNumber = (number: any, scale = true) => {
  if (isNaN(number) || number === "" || number === undefined) {
    return "0"
  }
  const num = parseFloat(number)

  if (num > 500000000 && scale) {
    return formatK(num.toFixed(0))
  }

  if (num === 0) {
    return "0"
  }

  if (num < 0.0001 && num > 0) {
    return "< 0.0001"
  }

  if (num > 1000) {
    return "" + Number(parseFloat(String(num)).toFixed(0)).toLocaleString()
  }

  return parseFloat(String(num)).toPrecision(4)
}
