import { Navbar, Text, Title } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { Flex, Box } from "components/Layout.component"
import { Spinner } from "components/loading/Spinner.component"
import { useAvailableCredit, useHasBuyerRole } from "features/shopkeeper/queries"
import { useIsBuyerOrCreator } from "features/tokendistributor/queries/adminQueries"
import { ConnectWalletModal } from "features/web3/ConnectWalletModal"
import React from "react"
import { NavLink, Link, useLocation } from "react-router-dom"
import { PAGE_URLS } from "routes"
import styled from "styled-components"
import { theme } from "theme"

type ROLES = "buyer" | "creatorOrBuyer"

interface IMenuItem {
  text: string
  url: string
  external?: boolean
  requiresRole?: ROLES
}

export const MenuItems: IMenuItem[] = [
  { text: "Deposit Tokens", url: PAGE_URLS.DISTRIBUTE_TOKENS },
  {
    text: "Create Distribution",
    url: PAGE_URLS.CREATE_TOKEN_DISTRIBUTION,
    requiresRole: "creatorOrBuyer",
  },
  { text: "Shopkeeper", url: PAGE_URLS.SHOPKEEPER, requiresRole: "buyer" },
  { text: "Referrals", url: PAGE_URLS.REFERRAL },
]

const SidebarBase = () => {
  const { data: hasBuyerRole } = useHasBuyerRole()
  const hasBuyerOrCreatorRoles = useIsBuyerOrCreator()
  const { data: availableCredit, isLoading } = useAvailableCredit()

  if (hasBuyerRole === undefined || hasBuyerOrCreatorRoles === undefined || isLoading) {
    return <Spinner />
  }

  return (
    <>
      {availableCredit && (
        <Navbar.Section>
          <Box height="50px">
            <Title order={5}>Available credits: {availableCredit.toNumber()}</Title>
          </Box>
        </Navbar.Section>
      )}
      <Navbar.Section grow>
        {MenuItems.map((item: IMenuItem) => {
          if (item.requiresRole === "buyer" && !hasBuyerRole) {
            return null
          }
          if (item.requiresRole === "creatorOrBuyer" && !hasBuyerOrCreatorRoles) {
            return null
          }
          return (
            <NavLink
              key={item.text}
              to={item.url}
              style={({ isActive }) => ({
                color: isActive ? "#FFFFFF" : "#E2BEE8",
                opacity: isActive ? 1 : 0.5,
                cursor: "pointer",
              })}
            >
              <Box py={2}>
                <Text align="center">{item.text}</Text>
              </Box>
            </NavLink>
          )
        })}
      </Navbar.Section>
    </>
  )
}

export const Sidebar = () => {
  return (
    <Navbar padding="xs" width={{ base: 200 }}>
      <SidebarBase />
    </Navbar>
  )
}
