import { AddressZero } from "@ethersproject/constants"
import { isAddress } from "helpers/useContract.hook"
import { useSnackbar } from "notistack"
import { useMutation } from "react-query"
import invariant from "tiny-invariant"

import { useShopkeeperContract } from "./useShopkeeper.hook"

interface IBuyCredits {
  amount: number
  inputAddress?: string
}
export const useBuyCredits = () => {
  const contract = useShopkeeperContract()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async ({ amount, inputAddress }: IBuyCredits) => {
      invariant(contract, "Contract not given")
      const grantToAddress = inputAddress || AddressZero
      invariant(
        isAddress(grantToAddress),
        "Grant to address must be valid address or left unspecfiied"
      )

      const tx = await contract.buy(amount, grantToAddress)
      const receipt = await tx.wait()
      if (receipt.status !== 1) {
        throw new Error("Buy credit transaction failed")
      }

      return amount
    },
    {
      onSuccess: (amount: number, { inputAddress }) => {
        enqueueSnackbar(`Bought ${amount} credits`, { variant: "success" })
        if (inputAddress) {
          enqueueSnackbar(`Granted creation permission to ${inputAddress}`, { variant: "success" })
        }
      },
    }
  )
}

export const useGrantCreationPerms = () => {
  const contract = useShopkeeperContract()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (grantToAddress: string) => {
      invariant(contract, "Contract not given")
      invariant(isAddress(grantToAddress), "Grant to address must be valid address")

      const tx = await contract.grantCreationRoleTo(grantToAddress)
      const receipt = await tx.wait()
      if (receipt.status !== 1) {
        throw new Error("Grant creation permission transaction failed")
      }
    },
    {
      onSuccess: (_, grantToAddress) => {
        {
          enqueueSnackbar(`Granted creation permission to ${grantToAddress}`, {
            variant: "success",
          })
        }
      },
    }
  )
}
export const useClaimRefund = () => {
  const contract = useShopkeeperContract()
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(
    async (address: string) => {
      invariant(contract, "Contract not given")
      invariant(isAddress(address), "Address must be valid address")

      const tx = await contract.refundUnused(address)
      const receipt = await tx.wait()
      if (receipt.status !== 1) {
        throw new Error("Refund transaction failed")
      }
    },
    {
      onSuccess: (_, address) => {
        {
          enqueueSnackbar(`Claimed refund and revoked perms of ${address}`, {
            variant: "success",
          })
        }
      },
    }
  )
}

export const useCheckHasUsed = () => {
  const contract = useShopkeeperContract()

  return useMutation(async (addressToCheck: string) => {
    invariant(contract, "Contract not given")
    invariant(isAddress(addressToCheck), "Address must be valid address")

    const hasUsed = await contract.hasUsedPerms(addressToCheck)
    return hasUsed as boolean
  })
}
