import { BigNumber, ethers } from "ethers"
import { groupBy, prop, uniq, pluck } from "ramda"

import type { IContributor } from "./types"

// parses contributors string into list containing addresses including
// duplicates
const parseRawContributors = (parsedText: string, numTokenDecimals: number) => {
  if (parsedText === "") {
    return []
  }

  const numLines = parsedText.split("\n").length
  // the 'g' flag indicates this is stateful. It remembers the last match index
  const pattern = RegExp(/(0x[0-9a-fA-F]{40}).+?([0-9.]+)/, "g")
  const addresses: IContributor[] = []
  // returns a string iterator of matches
  const matches = parsedText.matchAll(pattern)
  for (const match of matches) {
    addresses.push({
      address: ethers.utils.getAddress(match[1]),
      value: ethers.utils.parseUnits(match[2], numTokenDecimals),
    })
  }

  if (addresses.length !== numLines) {
    throw new Error(`${numLines} lines in input but only found ${addresses.length} contributors`)
  }

  return addresses
}

export interface IParseContributorResult {
  contributors: IContributor[]
  // number of parsed contributors (incl duplicates)
  totalContributions: number
  // number of unique contributors
  totalDistinctContributors: number
  // sum of total input
  totalRaised: BigNumber
}

// parses contributors and dedupes them, summing their values
export const parseContributors = (
  parsedText: string,
  numTokenDecimals: number
): IParseContributorResult => {
  const parsedContributors = parseRawContributors(parsedText, numTokenDecimals)
  // ensure addresses returned in the same order
  const uniqueAddresses = uniq(pluck("address", parsedContributors))
  // remove duplicates addresses and sum their values
  const contributors = groupBy(prop("address"), parsedContributors)

  const result = uniqueAddresses.map((address: string) => {
    return {
      address,
      value: pluck("value", contributors[address]).reduce((a, b) => a.add(b), BigNumber.from(0)),
    }
  })

  return {
    contributors: result,
    totalContributions: parsedContributors.length,
    totalDistinctContributors: result.length,
    totalRaised: result.reduce((a, b) => a.add(b.value), BigNumber.from(0)),
  }
}
